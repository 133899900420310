.bande-container{
    display: flex;
    background: #dfe7f2;
    height: 290px;
    width: 95vw;
    margin-bottom: 7rem;
    margin-top: 5rem;
    align-items: center;
    justify-content: space-between;
    padding: 3px ;
    z-index: 1;
}  
.apropos-image1{
    width: 360px;
    height: 288px;
    margin-left: 20rem;
    z-index: 3;
}
.bande-texte{
    margin-left: 15rem;
    position: relative;
    text-align: center;
    font-size: 40px; 
    color: #00155A;
}
@media screen and (max-width:768px) {
    .bande-container{
        flex-direction: column;
        height: 500px;
        width: 420px;
        align-items: center;
        justify-content: center;
        padding-right: 21rem ;
        
    }
    .apropos-image1{
        width: 350px;
        height: auto;
        justify-content: center;
    }
} 