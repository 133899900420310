@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);

*, ::before, ::after{
    box-sizing: border-box;
    font-family: Nunito, sans-serif;

}
body{
    background-color: #ffffff;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 100%;
    height: 100%;
}
.apropos-wrapper{
  width: 100vw;
  overflow-x: hidden;
  margin-left: 3rem;
  box-sizing: border-box;
  overflow: hidden;
  
}
.description-apropos{
    margin-bottom: 5rem;
    color: rgb(19, 48, 145);
    font-size: 5.8rem;
    margin-top: 13rem;
    margin-left: 0;
    
}
.text-descriptif-apropos{       
    font-size: 2.2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(19, 48, 145);
    line-height: 1.7;
    max-width: 85rem;
    min-width: 35rem;
    margin-bottom: 5rem;
}
.bengApropos{
    font-size: 3.7rem;
    color: rgb(19, 48, 145);
    font-weight: bold;
    margin-top: 4rem;
}
.histoire-texte{
  font-size: 1.9rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: rgb(19, 48, 145);
  line-height: 1.7;
  max-width: 65rem;
  min-width: 35rem;
  margin: 5rem 0;
  
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
  margin-right:10rem ;
}

.snip1256 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 290px;
  max-width: 360px;
  max-height: 280px;
  width: 100%;
  color: #ffffff;
  text-align: left;
  background-color: rgb(0, 21, 90);
  font-size: 16px;
  -webkit-perspective: 50em;
  perspective: 50em;
  
}

.snip1256 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.snip1256 img {
  opacity: 1;
  width: 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.snip1256 figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  -ms-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
  z-index: 1;
  opacity: 0;
  padding: 25px 35px;
}

.snip1256 h3,
.snip1256 p {
  line-height: 1.5em;
}

.snip1256 h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 800;
  text-transform: uppercase;
}
.barreBlanche{
  width: 5rem;
  height: 0.35rem;
  border-radius: 12px;
  background-color: #ffffff;
  margin-bottom: 1.8rem;
}
.snip1256 p {
  font-size: 0.8em;
  font-weight: 500;
  margin: 0 0 15px;
}



.snip1256:hover img,
.snip1256.hover img {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}

.snip1256:hover figcaption,
.snip1256.hover figcaption {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.container-histoire{
  padding: 40px 0;
  margin: 0 auto;
  margin-left: 0;
  width: 92%;  
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40vh;
 
}

.item {
  flex: 1;
  height: 85%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
  
  &:hover{
    flex: 7;
  }
}



