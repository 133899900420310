*::before{
    margin: 0;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
   
}
html{
    font-size: 60.5%;
}
