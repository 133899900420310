.footer-container{
    width: 100vw;
    background-color: rgb(0, 21, 90);
    height: auto;
    display: flex;
    justify-content: space-around;
    padding: 2rem 2rem;
    position: relative;
    bottom: 0;
    overflow: hidden; 
    z-index : 4;
    margin-top: 4rem;
   
}
.footer-logo{
    text-decoration: none;
}
.footer-logo-section{
    display: flex;
    flex-direction: column;
}
.logo-beng {
    box-shadow: none;
    
}
/*.mention-legale{
    color: rgb(173, 178, 181);
    font-size: 10px;
}*/
.footer-links{
    display: flex;
    justify-content: space-between;
    gap: 10rem;
    margin-top: 5rem;
    font-size: 1.8rem;
    
}
.footer-links-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.footer-links-element{
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-weight: bold;
}

.footer-links-element:hover{
    color: #e9e9e9;
    transition: 0.3s ease-in;
}

.footer-social-media-icons{
    margin-top: 3rem;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
}
.social-icon-link:hover{
    transition: 0.4s;
}
.social-icon-link:hover{
    transform: translateY(-3px);
}
.icon{
    color: #fff;
}
.icon-twitter{
    color: #49a1eb;
}
.icon-youtube{
    color: #eb3223;
}
.btn--footer{
    display: flex;
    margin-top: 4rem;
    color: #fff;
    background-color: rgb(0, 91, 255);
    text-align: center;
    align-items: center;
    padding: 1.5rem 3.8rem;
    cursor: pointer;
    border-radius: 0.225rem;
    border-style: hidden;
    font-weight: bold;
    margin-bottom: 7rem;
}
.telephone-b-eng {
    display: flex;
    gap: 9px;
    margin-top: 4rem;
    font-weight: bold;
    
}
.telephone{
    font-size: 17px;
    font-family: 'Times New Roman', Times, serif;
}
.num-tel{
    font-size: 17px; 
    font-family: 'Times New Roman', Times, serif; 
}

@media (max-width : 768px){
    .footer-container {
        flex-direction: column;
        height: auto; 
        padding: 6rem; 
    }  
    .footer-links{
       
        margin-top: 4rem;
        font-size: 1.3rem;
        
    } 
}


