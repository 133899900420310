.middleSection-container{
   top: 11vh;
   width: 100%;
}
/*img{
    width:  100%;
    height: 100vh;
    z-index: -1;
    box-shadow: 0px 5px 4px #9492ae;
}
.phrase-accueil{
    position: absolute;
    top: 50%;
    left: 42rem;
    transform: translate(-50%, -50%);
    font-size: 2.3rem;
    color: #efebeb;
    text-align: center;
}*/
section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 110px 100px;
}
@media (max-width: 1000px) {
	section {
		padding: 100px 50px;
	}
}
@media (max-width: 600px) {
	section {
		padding: 125px 30px;
	}
}
.box {
    position: relative;
    justify-content: center;
    min-height: 100vh;
    color: #fff;
    text-align: center;
    overflow: hidden; 
}

.box video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}


.box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(186, 186, 215, 0.2); 
    z-index: 0;
}

.box h1 {
    margin-bottom: 15px;
    font-size: 65px;
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    color: #162364;
    z-index: 1; 
    font-weight: 700;
}

.box h3 {
	margin-bottom: 40px;
	font-size: 25px;
    color: rgb(250, 74, 10);
    font-weight: bold;
}
.box .boxBtn {
    position: relative;
    z-index: 1;
	padding: 15px 35px;
	background: transparent;
	border-radius: 50px;
	color: #fff;
	text-transform: uppercase;
	border: 4px solid #fff;
	transition: all .5s;
    font-weight: 800;
    
}
.boxBtn:hover {
	background: #fff;
	color: #27357C;
    cursor: pointer;
}

@media (max-width: 800px) {
	.box {
		min-height: 600px;
	}
	.box h1 {
		font-size: 32px;
	}
	.box h3 {
		font-size: 20px;
	}
	.box a.boxBtn {
		padding: 15px 40px;
	}
}

.acDescription{
    font-size: 2rem;
    margin-top: 8rem;
    margin-bottom: 6rem;
    color: rgb(19, 48, 145);
    font-weight: bold;
    display: flex;
    margin-left: 10rem;
    min-width: 220px;
    max-width: 70%; 
    word-spacing: 2.5px;   
    line-height: 1.7;
    letter-spacing: 0.1px;
}
.decouverte-b-eng{
    font-size: 2rem;
    margin-left: 50px;
    color: rgb(19, 48, 145);
    
}
.decouverte-b-eng-title{
    font-size: 4.5rem;
    color: #27357C;
}
.decouverte-b-eng-text-descriptif{
    font-size: 2.5rem;
    color: #27357C;
    margin-top: 4rem;
    max-width: 550px;
    line-height: 1.7;
}
.barre{
    width: 10rem;
    height: 0.9rem;
    background-color: orangered;
    border-radius: 3rem;
    margin-top: 2rem;
}

.text-descriptif{
    margin-top: 35px;
    margin-right: 70rem;
    text-align: start;
}
@media screen and (max-width :768px) {
    .middleSection-container img{
        margin-right: 0;    
        width: 100%;  
    }
    .phrase-accueil{
        left: 25rem;
        font-size: 2rem;
        text-align: center; 
        color: #fff;
    }
    .description{
        font-size: 1.3rem;
        margin-left: 55px;
        margin-right: 10px;
    }
    .decouverte-b-eng-text-descriptif{
        width: 400px;
    }
    .acDescription{
        margin-left: 5rem;
    }
 }