@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

.opening-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100vw;
    background-attachment: fixed;
    position: fixed;
    position: relative;
    z-index: 0;
}

.phrase-contact {
    position: absolute;
    text-align: center;
    color: #ffffff;
    font-size: 4.5rem;
    font-weight: 900;
    top: 250px;
    z-index: 1;
}

.contact-container {
    width: 100vw;
}

.contact-wrapper {
    width: 100vw;
}

.contactez-nous-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contactez-nous {
    font-weight: bold;
    color: rgb(19, 48, 145);
    font-size: 4.5rem;
    margin-bottom: 2.5rem;
    font-family: "Montserrat", sans-serif;
}

.contact-content {
    max-width: 900px;
    margin: 12rem;
    display: flex;
    justify-content: space-around;
    gap: 10rem;
    align-items: center;
    width: 100%;
}

.social-media {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 2;
    margin-left: 10rem;
    align-items: center;
    margin-top: 9rem;
}

.contact-social-icon {
    font-size: 35px;
    color: rgb(10, 39, 136);
    transition: 0.3s ease-in;
}

.contact-social-icon:hover {
    transform: translateY(-6px);
}

.icon-name {
    text-decoration: none;
}

.formulaire-contact {
    display: flex;
    width: 600px;
    max-width: 95vw; /* Modifier la largeur maximale du formulaire */
    background: rgb(235, 244, 250);
    height: 80vh;
    border-radius: 3px;
    padding-right: 12rem;
    padding-left: 6rem;
    box-shadow: 2px 4px 5px #8a8997;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(18px);
}

.message-title {
    color: rgb(10, 39, 136);
    font-size: 24px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.box-input {
    display: flex;
    margin-top: 2.2rem;
    max-width: 500px;
    justify-content: space-between;
    align-items: center;
    color: #27357C;
    margin-left: 5rem;
}

.box-input input {
    width: 90%;
    border: none;
    padding: 1.6rem;
    border-bottom: 3px solid rgb(128, 147, 209);
    background: none;
    outline: none;
    color: #27357C;
    font-size: 2rem;
    font-family: 'Times New Roman', Times, serif;
}

textarea {
    width: 90%;
    height: 80px;
    padding: 6px;
    padding-bottom: 3rem;
    outline: none;
    border: none;
    box-sizing: border-box;
    border-bottom: 2.5px solid rgb(128, 147, 209);
    background: none;
    border-radius: 4px;
    outline: none;
    color: #27357C;
    font-size: 2rem;
}

.contact-form-error {
    font-size: 17px;
    font-style: italic;
    color: red;
}

.fb {
    color: #4267B2;
}

.tw {
    color: #49a1eb;
}

.in {
    color: rgb(10, 39, 136);
}

.yt {
    color: #eb3223;
}

.insta {
    color: black;
}

.envoyer {
    position: absolute;
    padding: 7px;
    text-align: center;
    background-color: rgba(0, 91, 255, 0.18);
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    width: 13rem;
    font-size: 20px;
    transition: 0.3s;
    background-color: rgb(10, 39, 136);
    margin-top: 9px;
    bottom: 4%;
    left: 35%;
    border-radius: 3px;
}

.envoyer:hover {
    color: rgb(10, 39, 136);
    background-color: #fff;
    border: 1px solid rgb(10, 39, 136);
}

@media screen and (max-width: 868px) {
    .formulaire-contact {
        margin-left: 3rem;
    }

    .phrase-contact {
        font-size: 40px;
        top: 160px;
    }

    .social-media {
        flex-direction: row;
        gap: 4rem;
        margin-right: 20%;
        margin-top: 0;
    }

    .contact-content {
        flex-direction: column-reverse;
    }

    .box-input {
        width: 370px;
    }

    .message-title {
        font-size: 25px;
    }

    .contactez-nous {
        font-size: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .contact-content{
        margin-left: 0.5rem;
        margin-top: 4rem;
    }
    .formulaire-contact {
        width: 90vw;
        margin: 0;
        padding: 0; /* Supprimer les paddings */
        left: 0;
    }

    .phrase-contact {
        font-size: 30px;
        top: 160px;
    }

    .message-title {
        font-size: 20px;
        text-align: center;
    }

    .box-input {
        width: 90%; /* Ajuster la largeur pour éviter le débordement */
    }
}
