*, ::before, ::after{
    box-sizing: border-box;

}
body{
    background-color: #fff;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.b-candidats{
    top: 11vh;
    margin-left: 5rem;   
}
.description-candidats{
    margin-top: 58px;
    color: rgb(19, 48, 145);
    font-size: 5.8rem;
    margin-top: 13rem;
    margin-left: 0;
}
.text-descriptif-candidats{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    max-width: 85rem;
    min-width: 35rem;
    margin-top: 5rem;
    color: rgb(19, 48, 145);
    font-size: 2.2rem;
    margin-bottom: 7rem;
    line-height: 1.8;
    word-spacing: 5px;
}
.corps-candidats{
    color:#27357C;  
}
.see-more{
    font-size:4.2rem;
}
.sorry-no-offer{
    margin: 4rem;
    font-size: 1.7rem;
    font-family: 'Times New Roman', Georgia, serif;
    color: rgb(19, 48, 145);
    font-weight: bold;
}

