body{
    background-color: #fff;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.formation-container{
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    box-sizing: border-box;
}
.opening-formation{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-size: cover;
    background-attachment: fixed;  
    position: relative;
    z-index: 0;
    overflow: hidden;
}
.phrase-ouverture{
    position: fixed;
    text-align: center;
    color: #fff;
    font-size: 50px;
    font-weight: 900;
    margin-top: 18rem;
    top: 110px;
    z-index: 1; 
    line-height: 1.5;
}
.intro-section{
    margin-top: 3.5rem;
    max-width: 1000px;
    line-height: 1.75;
    margin-left: 3rem;   
    font-weight: 100;
    color: rgb(19, 48, 145);
    font-size: 2.2rem;
    margin-bottom: 2.5rem;
}
.description-formations{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nosFormationsTitle{     
    font-weight: bold;
    color: rgb(19, 48, 145);
    font-size: 4.5rem;
    text-align: justify;
    z-index: 3;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.niveaux-choix-formation{
    color: #27357C;
    font-size: 4.2rem;
    position: relative;
    z-index: 1;
    left: 8rem;
    top: 5rem;
}
.barre-small{
    position: relative;
    width: 8rem;
    height: 0.6rem;
    background-color: orangered;
    left: 8rem;
    border-radius: 3rem;
    margin-bottom: 12rem;
    top : 7rem
}
.cartes-container{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 5rem;
}
.liste-externe {
    list-style: none;
    position: relative;
    z-index: 1;
    margin-left: 8rem; 
    margin-bottom: 12rem;   
} 
.liste-externe > li > span {
    font-weight: bold;
    font-size: 3rem;
    display: block;
    margin-bottom: 3rem;
    color: rgb(0, 21, 90);
}
.liste-externe > li{
    margin-bottom: 4rem;
}
  
.liste-interne {
    list-style-type: square;
    padding-left: 20px;
    margin: 7px 0;
}
  
.liste-interne > li {
    margin-bottom: 2.5rem;
    font-size: 2rem;
    color: #27357C ;
    
}

@media screen and (max-width: 768px) {
    .phrase-ouverture{  
        font-size: 40px;
        margin: 9rem 2rem;
    }
   
    .intro-section{
        font-size: 20px;
    }
    .formation-container h3{
        left: 3rem;
    }
    .barre-small{
        left: 3rem;
    }
}