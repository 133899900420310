.expertise-container{
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    box-sizing: border-box;
}
.opening-expertise{  
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-size: 100vw;
    background-attachment: fixed;  
    /* position: relative; */
    /* top: 110px; */
    z-index: 0;
}
.phrase-ouverture{
    position: fixed;
    text-align: center;
    color: #fff;
    font-size: 4.5rem;
    font-weight: 900;
    margin-top: 95px;
    top: 110px;
    z-index: 1;
}
.corps_expertise{
    display: flex;
    text-align: right;
    position: relative;
    z-index: 3;
    margin-bottom: 50px;
    max-width: 100vw;
}
.color-band{
    height: 280vh;
    background-color: #f1f4ff;
    width: 25%;
    bottom: 0;
}
.text_container{
    position: relative;
    z-index: 3;
    width: 700px;
    margin-top: 70px;
}
.text_content{
    font-size: 2rem;
    color: #27357C;
    margin-left: 8rem;
    font-weight:100;
    text-align: justify;
    line-height: 1.8;
}
.detail-expertise{
    width: 800px;
    margin-top: 30px;
    margin-left: 8rem;
}

[class^='titre-item'] {
    font-weight: bold;
    color: rgb(19, 48, 145);
    font-size: 4rem;
    text-align: start;
}

[class^='titre_wrapper']{
    margin-bottom: 30px;
}

[class^='detail-item']{
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
[class^='description-']{
    margin-right: 19px;
}
.detail-item-image{
    width: 300px;
    height: 240px;
}
.liste-expertise-item{
    font-size: 19px;
    line-height: 1.8;
    text-align: justify;
    color: #2f3b78;  
}
@media screen and (max-width : 768px) {
    [class^='detail-item']{    
        display: flex;
        flex-direction: column;
        margin-right: 24rem;
    }
    [class^='description-']{
        margin-right: 100px;
        margin-bottom: 3rem;
       
    }
    .text_content{
        margin-left: 4rem;
        font-size: 2rem;
        width:480px ;
    }
    .detail-expertise{
        margin-left: 4rem;
    }
    [class^='titre-item'] {
        font-size: 3.1rem;
        text-align: start;
        width: 550px;
        line-height: 1.7;
    }
    
}

