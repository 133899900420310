*, ::before, ::after{
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    
}
body{
    background-color: #fff;
    font-family: 'poppins', sans-serif;
    overflow-x: hidden;
}
