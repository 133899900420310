.formation-card {
    position: relative;
    background: rgb(235, 244, 250);
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 265px;
    border-radius: 16px;
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    box-shadow: 2px 4px 5px #8a8997;
    margin-bottom: 2rem;
    padding: 15px;
    
}
  
.formation-card-title {
    font-size: 2.6rem; 
    font-weight: bold;
    color: rgb(10, 39, 136);
    margin-bottom: 2rem;
}
  
.formation-card-description {
    font-size: 1.8rem; 
    color: rgb(10, 39, 136);
    line-height: 1.7;
}
  
.inscription-formation {
    position: absolute;
    padding: 7px;
    text-align: center;
    background-color: rgba(0, 91, 255, 0.18);
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    width: 13rem;
    font-size: 15px;
    transition: 0.3s;
    background-color: rgb(10, 39, 136);
    border-radius: 0.6rem;
    bottom: 4%;
    left: 25%;
}
.inscription-formation:hover{
    color: rgb(10, 39, 136) ;
    background-color: #fff ;
    border: 1px solid rgb(10, 39, 136);
}
  