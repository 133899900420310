.cartes__conteneur{
  margin-left: 50px;
}
.liste__cartes{
    margin: 0 auto;
    padding: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ajustez la taille des colonnes selon vos besoins */
    grid-gap: 35px;
    
}   

